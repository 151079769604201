import { ChangeDateFormat } from "@/lib/utils/common";
//import FormTabel from "./formTabel";
import VoyageInfo from "@/page/admin/myOrder/component/voyageInfo.vue";
import orderChange from "@/lib/data-service/haolv-default/consumer_flight_order_orderChange";
import change from "@/lib/data-service/haolv-default/consumer_flight_order_change";
import consumer_journey_addOrUpdateOverproof from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import NullData from "../../nullData";
import moment from "moment"
import Upload from "@/page/admin/myOrder/component/upload.vue";
import FlightClass from "@/page/admin/myOrder/component/flightClass/index.vue";
import ChangeFlightSearch from "@/page/admin/myOrder/component/change-flight-search";
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
import consumer_air_ticket_flightRules from '@/lib/common-service/consumer_air_ticket_flightRules'
import ChangeReturnClausePopup from "@/page/admin/travel-book/flight/component/changeReturnClausePopup/1.0.0/index.vue"; // 获取退改规则

export default {
    data() {
        return {
            orderNo: '',
            centerDialogVisible: false,
            ChangeDateFormat: ChangeDateFormat,
            detail: {},
            loading: false,
            changeSegmentList: {},
            needChangeSegmentList: [],
            formData: {
                chaReason: '',
                changeType: 1,
                chaReasonText: '',
                realReason: '',
                realReasonText: '',
                segmentList: [],
                url: [],
            },
            dialogVisible: false,
            canSubmit: true,


            changeForm: {},
            changeIndex: 0,
            oldSegmentList: [],
            changeSegmentList1: [],
            startPickerOptions: {
                disabledDate: time => {
                    return time.getTime() < Date.now() - 1000 * 3600 * 24;
                }
            },
            dialogVisible1: false,
            chaReasonList: [
                {
                    value: 1,
                    label: "自愿改签"
                },
                {
                    value: 2,
                    label: "非自愿改签"
                }
            ],
            newTravelUser: [],
            //ruleShow: false, // 退改规则弹窗
            ruleText: {},
            //ruleLoading: false,
            rules: {
                realReasonText: [
                    { required: true, message: '请输入改签原因', trigger: 'blur' },
                ]
            },
            refReasonEnum: [],

            ruleShow: false,
            ruleLoading: false,
            ruleTextList: [], // 退改规则
        }
    },
    components: {
        ChangeReturnClausePopup,
        FlightClass,
        VoyageInfo,
        NullData,
        ChangeFlightSearch,
        Upload,
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        this.getRefReasonEnum();
        this.reactDate();
        this.clearData();
        this.getDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
    },
    filters: {
        formatTime(val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
        },
    },
    methods: {
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 2}).then(res=>{
                let refReasonEnum = res.datas;
                refReasonEnum.forEach(value=>{
                    let text = '';
                    if (value.reasonType === 1) {
                        text+= '个人原因：'
                    }
                    if (value.reasonType === 2) {
                        text+= '航司原因：'
                    }
                    text+= value.msg;
                    text+=value.chaReason === 1 ? '（自愿改签）' : '（非自愿改签）'
                    console.log(text);
                    value.text = text;
                })
                this.refReasonEnum = refReasonEnum;
            })
        },
        lookChange() {
            this.$router.push({ name: "admin-my-order-flight-change-detail" });
        },
        goFlightList() {
            this.$router.push({ name: "admin-my-order-flight" });
        },
        checkForm() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate((valid) => {
                    resolve(valid)
                })
            })
        },
        async submit() {
            let checkResult = await this.checkForm()
            if (!checkResult) {
                return
            }
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false

            if (!this.formData.realReason){
                this.$message({ type: "warning", message: "请选择原因" });
                this.canSubmit = true;
                return;
            }

            if (this.formData.chaReason===2 && this.formData.url.length === 0) {
                this.$message({ type: "warning", message: "请上传附件" });
                this.canSubmit = true;
                return;
            }

            if (this.formData.segmentList.length === 0) {
                this.$message({ type: "warning", message: "请选择航班" });
                this.canSubmit = true;
                return;
            }
            if (this.formData.needChangeSegmentList.length > 1) {
                let segmentTypeList = [];
                this.formData.needChangeSegmentList.forEach(value => {
                    if (segmentTypeList.indexOf(value.segmentType) === -1) {
                        segmentTypeList.push(value.segmentType)
                    }
                })
                this.$message({ type: "warning", message: segmentTypeList.length > 1 ? "去程回程不能同时改签" : '航班号或舱位不一致，请分别提交改签' });
                this.canSubmit = true;
                return;
            }

            let overproofApplyList = []
            this.formData.segmentList.forEach(value => {
                let warningList = value.overproofApplyList
                warningList.forEach(value2 => {
                    let item = {
                        businessType: value2.businessType,
                        controlRule: value2.controlRules,
                        orderNo: value2.orderNo,
                        reason: value2.reason,
                        violationOfReason: value2.violationOfReason,
                        reserveContent: value2.violationOfReason,
                        standard: value2.standard,
                        seatName: value2.seatName,
                        standardContent: value2.seatName,
                        userId: value2.userId,
                        userName: value2.userRealName,
                        userRealName: value2.userRealName,
                        overproofType: 2
                    }
                    overproofApplyList.push(item)
                })
            })
            let checkParams ={
                evectionNo: this.detail.evectionNo,
                overproofApplyList
            }
            let [resultErr, result] = await awaitWrap(consumer_journey_addOrUpdateOverproof(checkParams))
            if (resultErr) {
                this.canSubmit = true
                return
            }
            let changeSegment = Object.values(this.changeSegmentList);
            changeSegment = [].concat(...changeSegment);
            let passengerNoList = []
            changeSegment.forEach(value => {
                if (passengerNoList.indexOf(value.psgNo) === -1) {
                    passengerNoList.push(value.psgNo)
                }
            })
            let data = {
                ticketNumberList: changeSegment.map(item => item.ticketNumber),
                adultTicketNo: changeSegment
                    .map(item => item.aduTicketNumber)
                    .join(","),
                passengerNoList: passengerNoList, // changeSegment.map(item => item.psgNo)
                orderNo: this.$route.query.orderNo,
                evectionNo: this.detail.evectionNo,
                manualStatus: 0
            };
            this.formData = Object.assign(this.formData, data);
            let params = JSON.parse(JSON.stringify(this.formData));
            if (params.url) {
                params.url = params.url.join(',');
            }
            let [err, res] = await awaitWrap(change(params))
            this.canSubmit = true
            if (err) {
                return
            }
            this.$message({
                type: "success",
                message: '提交改签成功'
            })
            this.$router.push({ name: 'admin-my-order-flight-changeList', });

        },
        getSegment(val, passenger, index) {
            this.setChangeSegment(passenger, val, index);
        },
        setChangeSegment(passenger, segment, index) {
            let passengerName = `${passenger.psgName}/${index}`;
            if (segment.length) {
                /**添加 */
                segment.map(item => {
                    item.name = passengerName.split("/")[0];
                    item.startTime = "";
                });
                this.changeSegmentList[passengerName] = segment;
            } else {
                /**清空 */
                delete this.changeSegmentList[passengerName];
            }
            // 筛选出航程
            let flightNoArr = []
            let needChangeSegmentList = []
            for (let segment in this.changeSegmentList) {
                this.changeSegmentList[segment].forEach(value => {
                    let arrIndex = flightNoArr.indexOf(value.flightNo)
                    if (arrIndex === -1) {
                        flightNoArr.push(value.flightNo)
                        value.userIdArr = [value.userId]
                        value.userInfoArr = [{
                            id: value.userId,
                            ticketNo: value.ticketNumber,
                            psgNo: value.psgNo
                        }]
                        needChangeSegmentList.push(value)
                    } else {
                        needChangeSegmentList[arrIndex].userIdArr.push(value.userId)
                        needChangeSegmentList[arrIndex].userInfoArr.push({
                            id: value.userId,
                            ticketNo: value.ticketNumber,
                            psgNo: value.psgNo
                        })
                    }
                })
            }
            this.needChangeSegmentList = needChangeSegmentList
            this.$set(
                this.formData,
                "changeSegmentList",
                JSON.parse(JSON.stringify(this.changeSegmentList))
            );
            this.$set(this.formData, 'needChangeSegmentList', JSON.parse(JSON.stringify(this.needChangeSegmentList)))
        },
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            this.loading = true;
            try {
                orderChange({ orderNo }).then(res => {
                    this.loading = true;
                    if (res.datas.changeRes) {
                        let detail = res.datas.changeRes
                        detail.creatType = parseInt(detail.creatType)
                        let hasChange = 0
                        if (detail.passengerList) {
                            detail.passengerList.forEach(value => {
                                value.changeSegmentList.forEach(value1 => {
                                    hasChange++
                                    value1.userId = parseInt(value.userId)
                                    value1.psgName = value.psgName
                                    value1.psgNo = value.psgNo
                                    value1.changeDate = value1.beginDate
                                })
                            })
                        }
                        this.detail = detail;
                        if (hasChange === 0) {
                            // 弹窗
                            this.dialogVisible = true
                        }
                    } else {
                        // 弹窗
                        this.dialogVisible = true
                    }
                })
                    .catch(() => {
                        this.loading = true;
                    });
            } catch {
            }
        },
        goBack() {
            this.dialogVisible = false
            this.$router.go(-1)
        },
        reactDate() {
            this.centerDialogVisible = false
            this.detail = {}
            this.changeSegmentList = {}
            this.needChangeSegmentList = []
            this.formData = {
                chaReason: '',
                changeType: 1,
                chaReasonText: '',
                realReason: '',
                realReasonText: '',
                segmentList: [],
                url: [],
            }
            this.dialogVisible = false
        },




        // 修改改签原因时触发
        changeFormDataType(val) {
            for (let item in this.formData.changeSegmentList) {
                this.formData.changeSegmentList[item].forEach(value => {
                    value.changeDate = value.beginDate
                })
            }
            this.changeSegmentList1 = []
        },
        /**选择航班传参 */
        getFlight(segment, airIndex) {
            this.airIndex = airIndex
            let obj = {
                cabinRank: segment.cabinRank,
                depDate: segment.changeDate,
                airlineName: segment.airlineName,
                airLineCode: segment.airlineCode, // 缺少二字码
                segmentTypeText: segment.segmentTypeText,
                depCityPort: {
                    name: segment.sCityName,
                    code: segment.scityCode || segment.sAirportCode
                },
                arrCityPort: {
                    name: segment.eCityName,
                    code: segment.ecityCode || segment.eAirportCode
                },
                flightNo: segment.flightNo,
                segmentNo: segment.segmentNo
            };
            /*if (this.formData.changeType === 2) {
              obj.flightNo = segment.flightNo
            }*/
            this.oldSegmentList[airIndex] = segment.segmentNo;
            this.changeIndex = airIndex;
            this.changeForm = obj;
            this.newTravelUser = segment.userIdArr
            this.dialogVisible1 = true;
        },
        /**获取新航程 */
        getSegment1(val) {
            let userInfoArr = this.formData.needChangeSegmentList[this.changeIndex].userInfoArr
            let violationResults = val.violationResults
            let ticketNumberList = []
            let passengerNoList = []
            if (violationResults.length > 0) {
                violationResults.forEach(value => {
                    userInfoArr.forEach(value1 => {
                        if (value.userId === value1.id) {
                            ticketNumberList.push(value1.ticketNo)
                            passengerNoList.push(value1.psgNo)
                        }
                    })
                })
            }
            let res = val.params.rqData.segmentList[0];
            let segment = {
                arrDate: res.arrDate,
                arrTime: res.arrTime,
                cabinName: res.cabinName,
                cabinCode: res.cabinCode,
                cabinRank: res.cabinRank,
                depDate: res.depDate,
                depTime: res.depTime,
                flightNo: res.flightNo,
                overproofApplyList: val.violationResults,
                segmentNo: this.oldSegmentList[this.changeIndex],
                ticketNumberList: ticketNumberList,
                passengerNoList: passengerNoList,
                changePrice: res.settlement,
                airportTax: res.airportTax,
                fuelTax: res.fuelTax,
                flightKey: res.flightKey,
                businessExt: res.businessExt,
                policyId: res.policyId,
                price: res.price,
                changeFace: res.price,
                discount: res.discount,
                depAirportName: res.depAirportName,
                depAirportCode: res.depAirportCode,
                arrAirportName: res.arrAirportName,
                arrAirportCode: res.arrAirportCode,
                depTerminal: res.depTerminal,
                arrTerminal: res.arrTerminal,
            };
            this.dialogVisible1 = false;
            this.$set(this.changeSegmentList1, this.changeIndex, [res]);
            this.$set(this.formData.segmentList, this.changeIndex, segment);
            this.formData.changeStatu = true;
        },
        clearData() {
            this.changeSegmentList1 = []
            this.changeIndex = 0
        },
        backRule() {
            this.ruleTextList = []
            this.ruleShow = true;
            let segmentNoList = [];
            let segmentApiList = [];
            this.detail.passengerList.forEach(value=>{
                value.changeSegmentList.forEach(value1 => {
                    if (segmentNoList.indexOf(value1.segmentNo) == -1) {
                        segmentNoList.push(value1.segmentNo);
                        segmentApiList.push(consumer_air_ticket_flightRules({
                            segmentNo: value1.segmentNo,
                        }))
                    }
                })
            })
            Promise.all(segmentApiList).then(res=>{
                let ruleTextList = [];
                res.forEach(value => {
                    ruleTextList.push(value.datas.rsData);
                })
                this.ruleTextList = ruleTextList;
                this.ruleLoading = false;
            })
        },
        changeReason(val) {
            console.log(val);
            this.refReasonEnum.forEach(value=>{
                if (value.code === val) {
                    this.formData.chaReason = value.chaReason;
                    this.formData.chaReasonText = value.chaReason === 1 ? '自愿改签' : '非自愿改签';
                    // this.formData.realReason = value.code
                    this.formData.realReasonText = value.msg
                    // this.form.realReasonText1 = value.msg + '（'+ (value.chaReason === 1 ? '自愿改签' : '非自愿改签') +'）';
                }
            })

        },
    }
}
